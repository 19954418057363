import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class StudentCoreTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverIndex: -1,
    };
  }

  handleMouseEnter = (index) => {
    this.setState({ hoverIndex: index });
  };

  handleMouseLeave = () => {
    this.setState({ hoverIndex: -1 });
  };

  render() {
    const teamData = [
      {
        name: "Abhinav Kumar",
        position: "Fest Organizer",
        image:
          "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/Core%20Team/Abhinav%20Kumar.jpg",
        link: "https://www.linkedin.com/in/abhinav-kumar-55a72223b/",
      },
      {
        name: "Divya Aggarwal",
        position: "Fest Organizer",
        image:
          "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/Core%20Team/Divya%20Aggarwal.jpg",
        link: "https://www.linkedin.com/in/divya098/",
      },
      {
        name: "Divye Gupta",
        position: "Fest Organizer",
        image:
          "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/Core%20Team/Divye%20Gupta.jpg",
        link: "https://www.linkedin.com/in/divye-gupta/",
      },
      {
        name: "Ekagrah Grover",
        position: "Fest Organizer",
        image:
          "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/Core%20Team/Ekagrh%20Grover.jpg",
        link: "https://www.linkedin.com/in/ekagrah-grover-08097a114/",
      },
      {
        name: "Harshita Gupta",
        position: "Fest Organizer",
        image:
          "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/Core%20Team/Harshita%20Gupta.jpg",
        link: "https://www.linkedin.com/in/harshita-gupta-335525208/",
      },
      {
        name: "Mayank Hora",
        position: "Fest Organizer",
        image:
          "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/Core%20Team/MayankHora.jpg",
        link: "https://www.linkedin.com/in/mayankhora/",
      },
    ];

    return (
      <div style={styles.container}>
        <h2 style={styles.sectionHeading}>Student Core Team</h2>
        <div style={styles.cardContainer}>
          {teamData.map((member, index) => (
            <Link
              key={member.name}
              to={member.link}
              style={styles.link}>
              <div
                style={{
                  ...styles.card,
                  ...(this.state.hoverIndex === index && styles.hoverEffect),
                }}
                className="hover-effect"
                onMouseEnter={() => this.handleMouseEnter(index)}
                onMouseLeave={this.handleMouseLeave}>
                <img
                  src={member.image}
                  alt={member.name}
                  style={styles.memberImage}
                />
                <div style={styles.memberInfo}>
                  <p>{member.name}</p>
                  <p>{member.position}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    marginBottom: "20px",
  },
  sectionHeading: {
    fontFamily: "Audiowide",
    fontSize: "2.5em",
    marginBottom: "10px",
    textAlign: "center",
    fontWeight: "bold",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  card: {
    width: "200px",
    height: "300px",
    padding: "10px",
    margin: "10px",
    backgroundColor: "#D6EAF8", // Light blue background
    borderRadius: "10px",
    textAlign: "center",
    cursor: "pointer",
    transition: "background-color 0.3s, border 0.3s",
    position: "relative",
    overflow: "hidden",
  },
  memberImage: {
    width: "100%",
    height: "200px", // Adjusted height for uniform size
    borderRadius: "50%",
    marginBottom: "10px",
    transition: "transform 0.3s",
  },
  memberInfo: {
    fontWeight: "bold",
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  hoverEffect: {
    backgroundColor: "#AED6F1",
    border: "2px dashed #2C3E50",
  },
};
