import React, { Component } from "react";

const sponsorsData = [
  {
    name: "Microsoft ADC",
    logo: "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/sponsors/Microsoft%20ADC.jpg",
    link: "https://www.microsoft.com/en-us/madc",
    type: "Official Community Partners",
  },
  {
    name: "Kwikpic",
    logo: "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/sponsors/Kwikpic.png",
    link: "https://www.kwikpic.in/landing/home",
    type: "Official Photo Sharing Platform",
  },
  {
    name: "Devfolio",
    logo: "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/sponsors/Devfolio.webp",
    link: "https://devfolio.co/",
    type: "Tech Platform Partners",
  },
  {
    name: "Unstop",
    logo: "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/sponsors/Unstop.png",
    link: "https://www.unstop.com",
    type: "Tech Platform Partners",
  },
  {
    name: "Coding Ninja",
    logo: "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/sponsors/codingninjas.jpg",
    link: "https://www.codingninjas.com",
    type: "Learning Partner",
  },
  {
    name: "ScribiQue",
    logo: "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/sponsors/ScribiQue.jpg",
    link: "https://scribie.com/",
    type: "Gifting Partners",
  },
  {
    name: "Upcoming Fest",
    logo: "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/sponsors/UpcomingFest.png",
    link: "https://upcomingfest.netlify.app/",
    type: "Community Partners",
  },
  {
    name: "DUCAT",
    logo: "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/sponsors/duCat.png",
    link: "https://www.ducatindia.com/",
    type: "Education Partners",
  },
  {
    name: "Kredent infoedge",
    logo: "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/sponsors/Kredent%20infoedge.jpg",
    link: "https://kredent.com/",
    type: "Voucher Partners",
  },
  {
    name: "HoverRobotix",
    logo: "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/sponsors/HoverRobotix.png",
    link: "https://www.hoverrobotix.com",
    type: "Voucher Partners",
  },
  {
    name: "Mentorxglobal",
    logo: "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/sponsors/Mentorxglobal.jpg",
    link: "https://thementorx.com/",
    type: "Voucher Partners",
  },
  {
    name: "Revup",
    logo: "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/sponsors/Revup.jpg",
    link: "https://getrevup.com/",
    type: "Voucher Partners",
  },
  {
    name: "Elearnmarkets",
    logo: "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/sponsors/Elearnmarkets.jpg",
    link: "https://www.elearnmarkets.com",
    type: "Voucher Partners",
  },
];

export default class Sponsors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoveredIndex: -1,
    };
  }

  handleMouseEnter = (index) => {
    this.setState({ hoveredIndex: index });
  };

  handleMouseLeave = () => {
    this.setState({ hoveredIndex: -1 });
  };

  render() {
    return (
      <div style={styles.container}>
        <h1 style={styles.heading}>Sponsors</h1>
        <div style={styles.sponsorContainer}>
          {sponsorsData.map((sponsor, index) => (
            <a
              key={index}
              href={sponsor.link}
              target="_blank"
              rel="noopener noreferrer"
              style={styles.sponsorLink}>
              <div
                style={{
                  ...styles.sponsorCard,
                  ...(this.state.hoveredIndex === index &&
                    styles.sponsorCardHover),
                }}
                onMouseEnter={() => this.handleMouseEnter(index)}
                onMouseLeave={this.handleMouseLeave}>
                <img
                  src={sponsor.logo}
                  alt={sponsor.name}
                  style={styles.sponsorLogo}
                />
                <div style={styles.sponsorType}>{sponsor.type}</div>
              </div>
            </a>
          ))}
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    width: "100%",
    padding: "20px",
    backgroundColor: "#f7f5eb", // Creamy white background
    color: "#2C3E50", // Dark color for text
  },
  heading: {
    fontFamily: "Audiowide",
    textAlign: "center",
    fontSize: "3em",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  sponsorContainer: {
    display: "flex",
    justifyContent: "center", // Center-align cards horizontally
    flexWrap: "wrap",
  },
  sponsorLink: {
    textDecoration: "none",
    width: "200px", // Set fixed width for each card
    margin: "10px", // Add margin to create spacing between cards
  },
  sponsorCard: {
    width: "200px", // Set fixed width for each card
    height: "250px", // Set fixed height for each card
    padding: "10px",
    backgroundColor: "#ffffff", // Set card background color to white
    borderRadius: "10px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Box shadow for elevation
    transition: "transform 0.3s, box-shadow 0.3s", // Transition effect for transform and box-shadow
    cursor: "pointer",
    overflow: "hidden",
    position: "relative",
    display: "flex",
    flexDirection: "column", // Align content vertically
  },
  sponsorLogo: {
    width: "100%", // Ensure the image fills its container
    height: "auto", // Allow the image to adjust its height proportionally
    borderRadius: "10px",
  },
  sponsorType: {
    marginTop: "auto", // Move to the bottom
    textAlign: "center", // Center-align the text
    fontSize: "1em",
    fontWeight: "bold",
    color: "#000", // Black color for sponsor type
    lineHeight: "1.2", // Increase line height for multiline text
    padding: "5px 0", // Add padding for better readability
    maxWidth: "100%", // Set maximum width for text
    overflowWrap: "break-word", // Allow text to wrap if it exceeds the width
    fontFamily: "Arial, sans-serif", // Set font family for sponsor type
  },
  sponsorCardHover: {
    transform: "scale(1.05)", // Scale up the card on hover
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)", // Increase box shadow on hover
  },
};
