import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Navbar(props) {
  useEffect(() => {
    // Apply the selected theme when the component mounts
    applyTheme();
  });

  function applyTheme() {
    document.body.classList.add("navbar-dark", "text-light");
  }

  function closeMobileMenu() {
    const navbarToggler = document.querySelector(".navbar-toggler");
    const navbarCollapse = document.querySelector("#navbarSupportedContent");

    if (navbarToggler && navbarCollapse) {
      navbarToggler.classList.add("collapsed");
      navbarCollapse.classList.remove("show");
    }
  }

  return (
    <div>
      <nav className={`navbar navbar-expand-lg navbar-dark bg-dark`}>
        <div className="container-fluid">
          <Link
            className="navbar-brand d-flex align-items-center"
            to="/"
            onClick={closeMobileMenu}>
            <img
              src={props.imageUrl}
              style={{ width: "45px", height: "45px" }}
              alt=""
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse"
            id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/"
                  onClick={closeMobileMenu}>
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/events"
                  onClick={closeMobileMenu}>
                  Events
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/facultyteam"
                  onClick={closeMobileMenu}>
                  Faculty Team
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/studentteam"
                  onClick={closeMobileMenu}>
                  Student Team
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
