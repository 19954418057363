import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./ScreenCSS/event.css";
import myEventDetailsObjects from "./EventsDetail/EventDetailsData.js";

class Event extends Component {
  render() {
    return (
      <div>
        <div className="header">
          <h1 style={{ fontWeight: "bold" }}>EVENTS</h1>
        </div>
        <body1>
          <ul className="tilesWrap">
            {myEventDetailsObjects.map((event) => (
              <li key={event.id}>
                <h2>{event.id}</h2>
                <h3>{event.eventName}</h3>
                <p className="eventDescription">
                  {event.eventDescription}
                </p>{" "}
                <Link to={`/event-details/${event.id}`}>
                  <button>Read more</button>
                </Link>
              </li>
            ))}
          </ul>
        </body1>
      </div>
    );
  }
}

export default Event;
