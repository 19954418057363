import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Components/NavBar";
import React from "react";
import Home from "./Screens/home";
import AboutUs from "./Screens/Aboutus";
import NumberValue from "./Screens/NumberValue";
import Sponsors from "./Screens/sponsors";
import OurTeam from "./Screens/OurTeam";
import FAQ from "./Screens/FAQ";
import Footer from "./Screens/Footer";
import Event from "./Screens/Event";
import EventsDetails from "./Screens/EventsDetail/EventsDetails";
import OurFacultyTeam from "./Screens/FacultyTeam";
import OurStudentTeam from "./Screens/StudentTeam";

function App() {
  const isHome = window.location.pathname === "/";

  return (
    <Router forceRefresh={true}>
      <Navbar imageUrl="https://raw.githubusercontent.com/bvestx/BvestxAsset/main/Copy%20of%20bvest%20logo.png" />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <div className="home">
                <Home />
              </div>
              <AboutUs />
              <NumberValue />
              <Sponsors />
              <OurTeam />
              <div className="faq">
                <FAQ />
              </div>
              <Footer isHome={isHome} />
            </>
          }
        />
        <Route
          path="/events"
          element={
            <>
              <Event />
              <Footer isHome={isHome} />
            </>
          }
        />
        <Route
          path="/event-details/:eventId"
          element={
            <>
              <EventsDetails />
              <Footer isHome={isHome} />
            </>
          }
        />
        <Route
          path="/facultyteam"
          element={
            <>
              <OurFacultyTeam />
              <Footer isHome={isHome} />
            </>
          }
        />
        <Route
          path="/studentteam"
          element={
            <>
              <OurStudentTeam />
              <Footer isHome={isHome} />
            </>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
