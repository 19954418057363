// EventsDetails.js
import React from "react";
import { useParams } from "react-router-dom";
import myEventDetailsObjects from "./EventDetailsData.js";

function EventsDetails() {
  const { eventId } = useParams();
  const eventDetails = myEventDetailsObjects.find(
    (event) => event.id === Number(eventId)
  );

  if (!eventDetails) {
    // Handle case where event details are not found
    return <div>Event details not found</div>;
  }

  return (
    <div>
      <div
        style={{
          background: "rgba(0, 0, 0, 0.5)", // Semi-transparent black shade
          padding: "20px",
          borderRadius: "10px",
          maxWidth: "600px",
          margin: "auto",
          marginTop: "100px",
          marginBottom: "100px",
          color: "white",
          textAlign: "center", // Centering text
        }}>
        <h1 style={{ fontFamily: "Georgia, serif" }}>
          {eventDetails.eventName}
        </h1>
        <p
          style={{
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            textAlign: "justify",
          }}>
          {eventDetails.eventDescription}
        </p>
        <br />
        <h2 style={{ fontFamily: "Georgia, serif" }}>Game Format</h2>
        <p
          style={{
            fontFamily: "Roboto, sans-serif",
            fontSize: "16px",
            marginTop: "10px",
          }}>
          {eventDetails.gameFormat}
        </p>
        {eventDetails.viewMore && eventDetails.viewMore.length > 2 && (
          <p style={{ marginTop: "20px" }}>
            For More Details{" "}
            <a
              style={{ color: "#ffbaff" }}
              href={eventDetails.detailsLink}
              target="_blank"
              rel="noreferrer">
              Click Here
            </a>
          </p>
        )}
        <h2 style={{ fontFamily: "Georgia, serif" }}>Date of Event</h2>
        <p
          style={{
            fontFamily: "Roboto, sans-serif",
            fontSize: "16px",
            marginTop: "10px",
          }}>
          {eventDetails.date}
        </p>
        <h2 style={{ fontFamily: "Georgia, serif" }}>Venue</h2>
        <p
          style={{
            fontFamily: "Roboto, sans-serif",
            fontSize: "16px",
            marginTop: "10px",
          }}>
          {eventDetails.venue}
        </p>
        <h2 style={{ fontFamily: "Georgia, serif" }}>
          Entry Fee: {eventDetails.entryFee}
        </h2>
        <div>
          <a
            style={{
              textDecoration: "none",
              color: "white",
              background: "#4CAF50", // Green background color
              padding: "10px 20px", // Padding around the button
              borderRadius: "5px", // Rounded corners
              display: "inline-block",
              marginTop: "20px",
              cursor: "pointer",
            }}
            href={eventDetails.registrationLink}
            target="_blank"
            rel="noreferrer">
            Register Now
          </a>
        </div>
      </div>
    </div>
  );
}

export default EventsDetails;
