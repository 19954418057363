import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLargeScreen: window.innerWidth >= 500,
      displayText: "Decoding The Decade",
      showAnimation: true,
      hoverIndex: null,
    };
    this.textChangeTimer = null;
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.startTextChange();
    setTimeout(() => {
      this.stopTextChange();
      this.resetText();
      this.setState({
        showAnimation: false,
      });
    }, 2000); // Show animation for 2 seconds
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    clearTimeout(this.textChangeTimer);
  }

  handleResize = () => {
    this.setState({
      isLargeScreen: window.innerWidth >= 768,
    });
  };

  scrambleText = (text) => {
    // Scramble the text by shuffling its characters
    return text
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("");
  };

  startTextChange = () => {
    this.textChangeTimer = setInterval(() => {
      this.setState((prevState) => ({
        displayText: this.scrambleText(prevState.displayText),
      }));
    }, 100); // Change every 100 milliseconds
  };

  stopTextChange = () => {
    clearInterval(this.textChangeTimer);
  };

  resetText = () => {
    this.setState({
      displayText: "Decoding The Decade", // Reset to the actual text
    });
  };

  handleHover = (index) => {
    this.setState({ hoverIndex: index });
  };

  handleMouseLeave = () => {
    this.setState({ hoverIndex: null });
  };

  render() {
    const { isLargeScreen, displayText, showAnimation, hoverIndex } =
      this.state;

    const divStyle = {
      display: "flex",
      flexDirection: isLargeScreen ? "row" : "column",
      justifyContent: "space-around",
      alignItems: "center",
      height: "90vh", // Ensure the div covers the entire viewport height
      backgroundImage: isLargeScreen
        ? `linear-gradient(rgba(19, 2, 35, 0.75), rgba(0, 0, 0, 0.75)), url("https://raw.githubusercontent.com/bvestx/BvestxAsset/main/image.jpg")`
        : `url("https://raw.githubusercontent.com/bvestx/BvestxAsset/main/mobilelogo.png")`,
      backgroundSize: "cover", // Set background size to cover
      fontFamily: "Arial, sans-serif", // Use a different font style
    };

    const cafeInnerStyle = {
      fontSize: "5vw", // Adjust the font size as needed
      fontWeight: "bold",
      fontFamily: "Magnat",
      transition: "all 0.1s ease-in-out", // Fast transition for a smooth effect
      animation: showAnimation ? "rotateText 5s infinite" : "none", // Rotate text animation
    };

    const titleCardStyle = {
      backgroundColor: "#0A071E",
      borderRadius: "15px",
      padding: "20px",
      width: "100%",
      margin: isLargeScreen ? "0" : "10px", // Set margin to 0 for large screens, and 10px for small screens
    };

    const bvestx24Style = {
      fontFamily: "Times New Roman, serif",
      fontWeight: "bold",
      fontSize: "4vmax",
    };

    const calendarIconStyle = {
      margin: "0 5px ",
      fontSize: isLargeScreen ? "1.7em" : "1.2em",
    };

    const dateStyle = {
      fontFamily: "Cardo, serif",
      fontSize: isLargeScreen ? "1.7em" : "1.2em",
    };

    const buttonStyle = {
      borderRadius: "20px",
      padding: "10px 20px",
      color: "#fff",
      fontWeight: "bold",
      cursor: "pointer",
      border: "none",
      outline: "none",
      marginRight: isLargeScreen ? "5rem" : "1rem", // Adjust marginRight based on screen size
      transition: "background-color 0.3s ease",
    };

    const buttonsContainerStyle = {
      display: "flex",
      flexDirection: "row", // Align buttons vertically
      alignItems: "flex-start", // Align buttons to the left
      marginTop: isLargeScreen ? "20px" : "10px",
    };

    return (
      <div
        id="app"
        style={divStyle}>
        <div
          className="title"
          style={isLargeScreen ? {} : titleCardStyle}>
          <div className="cafe">
            <div style={cafeInnerStyle}>{displayText}</div>
          </div>
          <div style={bvestx24Style}>
            BVESTX <span>'24</span>
          </div>
          <div>
            <FontAwesomeIcon
              icon={faCalendar}
              style={calendarIconStyle}
            />
            <span style={dateStyle}> 05th & 06th March, 2024</span>
          </div>
          <div style={buttonsContainerStyle}>
            <a
              href="https://unstop.com/college-fests/bvest24-bharati-vidyapeeths-college-of-engineering-208728"
              onMouseEnter={() => this.handleHover(0)}
              onMouseLeave={this.handleMouseLeave}>
              <button
                style={{
                  ...buttonStyle,
                  backgroundColor:
                    hoverIndex === 0
                      ? "rgba(128, 0, 128, 1)"
                      : "rgba(128, 0, 128, 0.8)",
                }}>
                Events
              </button>
            </a>
            <a
              href="https://hackbvp6.devfolio.co/"
              onMouseEnter={() => this.handleHover(1)}
              onMouseLeave={this.handleMouseLeave}>
              <button
                style={{
                  ...buttonStyle,
                  backgroundColor:
                    hoverIndex === 1
                      ? "rgba(128, 0, 128, 1)"
                      : "rgba(128, 0, 128, 0.8)",
                }}>
                Hack@Bvp6.0
              </button>
            </a>
          </div>

          <p></p>
        </div>

        {isLargeScreen && (
          <div className="image">
            <img
              src="https://raw.githubusercontent.com/bvestx/BvestxAsset/main/Copy%20of%20bvest%20logo.png"
              style={{ height: "40vw", width: "auto" }}
              alt=""
            />
          </div>
        )}
      </div>
    );
  }
}
