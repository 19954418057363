import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class FacultyCoordinators extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverIndex: -1,
    };
  }

  handleMouseEnter = (index) => {
    this.setState({ hoverIndex: index });
  };

  handleMouseLeave = () => {
    this.setState({ hoverIndex: -1 });
  };

  render() {
    const teamData = [
      {
        name: "Mr. Mohit Tiwari",
        image:
          "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/FacultyCordinators/MohitTiwari.webp",
        link: "",
      },
      {
        name: "Mr. Rajat Gupta",
        image:
          "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/FacultyCordinators/RajatGupta.webp",
        link: "",
      },
      {
        name: "Ms. Amrita Ticku",
        image:
          "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/FacultyCordinators/AmritaTikku.webp",
        link: "",
      },
      {
        name: "Dr. Srishti Vashishtha",
        image:
          "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/FacultyCordinators/Srishti.webp",
        link: "",
      },
      {
        name: "Ms. Rachna Narula",
        image:
          "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/FacultyCordinators/Rachna.webp",
        link: "",
      },
      {
        name: "Mr. Vijay Kumar",
        image:
          "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/FacultyCordinators/Vijay.webp",
        link: "",
      },
      {
        name: "Ms. Nitasha Rathore",
        image:
          "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/FacultyCordinators/Nitasha.webp",
        link: "",
      },
      {
        name: "Dr. Aarti Sehwag",
        image:
          "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/FacultyCordinators/Aarti.webp",
        link: "",
      },
      {
        name: "Ms. Deepika Yadav",
        image:
          "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/FacultyCordinators/Deepika.webp",
        link: "",
      },
      {
        name: "Ms. Akanksha Kochhar",
        image:
          "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/FacultyCordinators/Akansha.webp",
        link: "",
      },
      {
        name: "Ms. Nupur Chugh",
        image:
          "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/FacultyCordinators/Nupur.webp",
        link: "",
      },
      {
        name: "Dr. Rakhi",
        image:
          "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/FacultyCordinators/Rakhi.webp",
        link: "",
      },
    ];

    return (
      <div style={styles.container}>
        <h2 style={styles.sectionHeading}>Faculty Coordinator Team</h2>
        <div style={styles.cardContainer}>
          {teamData.map((member, index) => (
            <Link
              key={member.name}
              to={member.link}
              style={styles.link}>
              <div
                style={{
                  ...styles.card,
                  ...(this.state.hoverIndex === index && styles.hoverEffect),
                }}
                className="hover-effect"
                onMouseEnter={() => this.handleMouseEnter(index)}
                onMouseLeave={this.handleMouseLeave}>
                <img
                  src={member.image}
                  alt={member.name}
                  style={styles.memberImage}
                />
                <div style={styles.memberInfo}>
                  <p>{member.name}</p>
                  {/* <p>{member.department}</p> */}
                  <p>Faculty Coordinator</p>{" "}
                  {/* Added Faculty Coordinator label */}
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    marginBottom: "20px",
  },
  sectionHeading: {
    fontFamily: "Audiowide",
    fontSize: "2.5em",
    marginBottom: "10px",
    textAlign: "center",
    fontWeight: "bold",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  card: {
    width: "200px", // Initial width of the card
    flex: "1 1 200px", // Flex properties to allow flexibility
    padding: "10px",
    height: "275px",
    margin: "10px",
    backgroundColor: "#D6EAF8", // Light blue background
    borderRadius: "10px",
    textAlign: "center",
    cursor: "pointer",
    transition: "background-color 0.3s, border 0.3s",
    position: "relative",
    overflow: "hidden",
  },
  memberImage: {
    width: "100%",
    height: "70%",
    borderRadius: "50%",
    marginBottom: "10px",
    transition: "transform 0.3s",
  },
  memberInfo: {
    fontWeight: "bold",
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  hoverEffect: {
    backgroundColor: "#AED6F1",
    border: "2px dashed #2C3E50",
  },
};
