import React, { Component } from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faInternetExplorer,
} from "@fortawesome/free-brands-svg-icons";
import "./ScreenCSS/footer.css";
// import img from "../Asset/ak.png";

export default class Footer extends Component {
  render() {
    const { isHome } = this.props;

    return (
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-row">
            <div className="footer-col">
              <img
                // src={img}
                src="https://raw.githubusercontent.com/bvestx/BvestxAsset/main/Copy%20of%20bvest%20logo.png"
                alt="BVESTX"
                className="footer-logo"
              />
            </div>

            <div className="footer-col">
              <h4>Address</h4>
              <a
                href="https://maps.app.goo.gl/7QK2kLndh4zwcGVT8"
                target="_blank"
                rel="noopener noreferrer"
                className="bvcoe-website">
                <p>Bharati Vidyapeeth's College Of Engineering</p>
                <p>Paschim Vihar, New Delhi - 63</p>
              </a>
            </div>

            <div className="footer-col">
              <h4>Help</h4>
              <ul>
                <li>
                  {isHome ? (
                    <ScrollLink
                      to="faq"
                      smooth={true}
                      duration={100}>
                      FAQs
                    </ScrollLink>
                  ) : (
                    <RouterLink to="/">FAQs</RouterLink>
                  )}
                </li>
                <li>
                  {isHome ? (
                    <ScrollLink
                      to="home"
                      smooth={true}
                      duration={300}>
                      Home
                    </ScrollLink>
                  ) : (
                    <RouterLink to="/">Home</RouterLink>
                  )}
                </li>
                <li>
                  <a
                    href="mailto:bvestx2023@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>

            <div className="footer-col">
              <h4>Follow Us</h4>
              <div className="social-links">
                <a href="https://www.facebook.com/profile.php?id=100090478012178">
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a href="https://www.instagram.com/bvest.bvcoe/">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a href="https://twitter.com/2020Bvest">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="https://bvcoend.ac.in/">
                  <FontAwesomeIcon icon={faInternetExplorer} />
                </a>
              </div>
              <p>Abhinav Kumar - 9350258260</p>
              <p>Mayank Hora - 8447407225</p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
