import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class StudentCoordinators extends Component {
  constructor(props) {
    super(props);
    this.state = {
      managerHoverIndex: -1,
      coordinatorHoverIndex: -1,
    };
  }

  handleMouseEnter = (index, isManager) => {
    isManager
      ? this.setState({ managerHoverIndex: index })
      : this.setState({ coordinatorHoverIndex: index });
  };

  handleMouseLeave = () => {
    this.setState({ managerHoverIndex: -1, coordinatorHoverIndex: -1 });
  };

  render() {
    const eventCoordinatorList = [
      {
        name: "Aditya Kumar Singh",
        department: "Design",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventCoordinators/AdityaKumarSingh.jpg",
        link: "https://www.linkedin.com/in/aditya-a2003ks?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      },
      {
        name: "Ananya Verma",
        department: "Documentation",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventCoordinators/AnanyaVerma.jpg",
        link: "https://www.linkedin.com/in/ananya-verma-36457826a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
      },
      {
        name: "Aonusha Saigal",
        department: "Design",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventCoordinators/AonushaSaigal.jpg",
        link: "www.linkedin.com/in/aonushasaigal",
      },
      {
        name: "Ashish Kumar",
        department: "Event management and coordinator",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventCoordinators/AshishKumar.jpg",
        link: "https://www.linkedin.com/in/ashish-kumar-a27698255?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      },
      {
        name: "Harshita",
        department: "Sponsorship",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventCoordinators/Harshita.jpg",
        link: "https://www.linkedin.com/in/harshita-991138227/",
      },
      {
        name: "Jashith Arora",
        department: "Design",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventCoordinators/Jashith.jpg",
        link: "https://in.linkedin.com/in/jashith-arora-413a4a294",
      },
      {
        name: "Krish Mutreja",
        department: "Design",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventCoordinators/Krish.jpg",
        link: "https://www.linkedin.com/in/krish-mutreja-046546251?trk=contact-info",
      },
      {
        name: "Naman",
        department: "Design",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventCoordinators/Naman.jpg",
        link: "https://www.linkedin.com/in/naman-arora-4b2b251a9/",
      },
      {
        name: "Nishchay Gupta",
        department: "Documentation",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventCoordinators/Nishchay.jpg",
        link: "www.linkedin.com/in/nishchay-gupta-417619283",
      },
      {
        name: "Priyanshu Satapathy",
        department: "Sponsorship",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventCoordinators/Priyanshu.jpg",
        link: "https://www.linkedin.com/in/priyanshu-satapathy-02233626b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      },
      {
        name: "Rohit",
        department: "Event Management and Coordinator",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventCoordinators/Rohit.jpg",
        link: "http://linkedin.com/in/rohit-dhull-799a6b2a5",
      },
      {
        name: "Shreya Singh",
        department: "Event Management and Coordinator",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventCoordinators/Shreya.jpg",
        link: "https://www.linkedin.com/in/shreya-singh-89393b279?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
      },
      {
        name: "Soumyaa Miglani",
        department: "Sponsorship",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventCoordinators/Soumyaa.jpg",
        link: "https://www.linkedin.com/in/soumyaa-miglani-474695256/",
      },
      {
        name: "Vaibhav",
        department: "Event Management and Coordinator",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventCoordinators/Vaibhav.jpg",
        link: "https://www.linkedin.com/in/vaibhav-rajput-2790182a7?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      },
      {
        name: "Vaibhav Gupta",
        department: "Event Co-ordinator",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventCoordinators/VaibhavGupta.jpg",
        link: "https://www.linkedin.com/in/vaibhav-gupta-195548295/",
      },
      {
        name: "Vaijayanthi Sambath Kumar",
        department: "Documentation",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventCoordinators/Vaijayanthi.jpg",
        link: "https://www.linkedin.com/in/vaijayanthi-sambath-kumar?utm_source=share&utm_campaign=share_via",
      },
      {
        name: "varun kaushik",
        department: "Event coordinator",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventCoordinators/Varun.jpg",
        link: "https://www.linkedin.com/in/varun-kaushik-2138a3259?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      },
    ];

    const eventManagerList = [
      {
        name: "Aditya Bansal",
        department: "Documentation",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventManager/Aditya.jpg",
        link: "https://www.linkedin.com/in/aditya-bansal-37b04a230/",
      },
      {
        name: "Ananya Sharma",
        department: "Hackbvp",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventManager/Ananya.jpg",
        link: "https://www.linkedin.com/in/ananya-sharma-155257201?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      },
      {
        name: "Ansh Agarwal",
        department: "Sponsorship",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventManager/Ansh.jpg",
        link: "https://www.linkedin.com/in/ansh-agarwal-034470167/",
      },
      {
        name: "Archie Vijay",
        department: "Social Media",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventManager/Archie.jpg",
        link: "https://www.linkedin.com/in/archie-vijay-857b43227?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
      },
      {
        name: "Ashish Dhiman",
        department: "Logistic and Discipline",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventManager/Ashish.jpg",
        link: "https://www.linkedin.com/in/ashishdhiman0407?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      },
      {
        name: "Bandaru Yogesh Kumar",
        department: "Social media and logistics",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventManager/Bandaru.jpg",
        link: "https://www.linkedin.com/in/bandaru-yogesh-kumar-52807122b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      },
      {
        name: "Bhoomi Khurana",
        department: "Design",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventManager/Bhoomi.jpg",
        link: "https://www.linkedin.com/in/bhoomikhurana",
      },
      {
        name: "Himanshu Dadhwal",
        department: "Design",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventManager/Himanshu.jpg",
        link: "https://www.linkedin.com/in/himanshu-dadhwal-64b740229?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      },
      {
        name: "Mukul Gupta",
        department: "Event Management and Coordinator",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventManager/Mukul.jpg",
        link: "https://www.linkedin.com/in/mukul-gupta-74b869256?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      },
      {
        name: "Ravdeep Singh",
        department: "Sponsorship",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventManager/Ravdeep.jpg",
        link: "https://www.linkedin.com/in/ravdeep-singh-698861229/",
      },
      {
        name: "Riya Aggarwal",
        department: "Design",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventManager/Riya.jpg",
        link: "https://www.linkedin.com/in/riya-aggarwal-69081624b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      },
      {
        name: "Shivani Goyal",
        department: "Social Media",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventManager/Shivani.jpg",
        link: "https://www.linkedin.com/in/shivani-goyal-34a86322b",
      },
      {
        name: "Sparsh Prakash",
        department: "Hackbvp",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventManager/Sparsh.jpg",
        link: "www.linkedin.com/in/sparsh-prakash-a5672b228",
      },
      {
        name: "Tanish Bajaj",
        department: "Design",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventManager/Tanish.jpg",
        link: "https://www.linkedin.com/in/tanish-bajaj-365394220?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      },
      {
        name: "Tushar Singh",
        department: "Design",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventManager/Tushar.jpg",
        link: "https://www.linkedin.com/in/tushar-singh-9a7815228?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      },
      {
        name: "Udit Hasija",
        department: "Hack@Bvp",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventManager/Udit.jpg",
        link: "https://www.linkedin.com/in/udit-hasija-73b7281ba?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      },
      {
        name: "Yash Dhanwaria",
        department: "Event Management and Coordinator",
        image:
          "https://raw.githack.com/bvestx/BvestxAsset/main/EventManager/Yash.jpg",
        link: "https://www.linkedin.com/in/yash-dhanwaria-30997b178/",
      },
    ];

    return (
      <div style={styles.container}>
        <h2 style={styles.sectionHeading}>Event Managers</h2>
        <div style={styles.cardContainer}>
          {eventManagerList.map((manager, index) => (
            <Link
              key={manager.name}
              to={manager.link}
              style={styles.link}>
              <div
                style={{
                  ...styles.card,
                  ...(this.state.managerHoverIndex === index &&
                    styles.hoverEffect),
                }}
                className="hover-effect"
                onMouseEnter={() => this.handleMouseEnter(index, true)}
                onMouseLeave={this.handleMouseLeave}>
                <img
                  src={manager.image}
                  alt={manager.name}
                  style={styles.memberImage}
                />
                <div style={styles.memberInfo}>
                  <p>{manager.name}</p>
                  <p>Event Manager</p>
                  {/* <p>{manager.department}</p> */}
                </div>
              </div>
            </Link>
          ))}
        </div>

        <h2 style={styles.sectionHeading}>Event Coordinators</h2>
        <div style={styles.cardContainer}>
          {eventCoordinatorList.map((coordinator, index) => (
            <Link
              key={coordinator.name}
              to={coordinator.link}
              style={styles.link}>
              <div
                style={{
                  ...styles.card,
                  ...(this.state.coordinatorHoverIndex === index &&
                    styles.hoverEffect),
                }}
                className="hover-effect"
                onMouseEnter={() => this.handleMouseEnter(index, false)}
                onMouseLeave={this.handleMouseLeave}>
                <img
                  src={coordinator.image}
                  alt={coordinator.name}
                  style={styles.memberImage}
                />
                <div style={styles.memberInfo}>
                  <p>{coordinator.name}</p>
                  <p>Event Coordinator</p>
                  {/* <p>{coordinator.department}</p> */}
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    width: "100%",
    padding: "20px",
    backgroundColor: "#f7f5eb", // Creamy white background
    color: "#2C3E50", // Dark color for text
  },
  sectionHeading: {
    fontFamily: "Audiowide",
    fontSize: "2em",
    marginBottom: "10px",
    marginTop: "20px",

    textAlign: "center",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  card: {
    width: "200px",
    height: "320px", // Set a fixed height for all cards
    padding: "10px",
    margin: "10px",
    backgroundColor: "#D6EAF8", // Light blue background
    borderRadius: "10px",
    textAlign: "center",
    cursor: "pointer",
    transition: "background-color 0.3s, border 0.3s",
    position: "relative",
    overflow: "hidden",
  },
  memberImage: {
    width: "100%",
    height: "180px",
    borderRadius: "50%",
    marginBottom: "10px",
    transition: "transform 0.3s",
  },
  memberInfo: {
    fontWeight: "bold",
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  hoverEffect: {
    backgroundColor: "#AED6F1",
    border: "2px dashed #2C3E50",
  },
};
