const myEventDetailsObjects = [
  {
    id: 1,
    eventName: "BKL( Bharati Kricket League )",
    eventDescription:
      "Experience the thrilling cricket team formation event! Teams vie in a virtual auction to assemble a 12-player squad under a 100 Cr budget. With 5 bowlers, 2 all-rounders, 5 batsmen, and a wicket-keeper chosen from 200 players, strategic bidding is essential. Success hinges on successive bids and team integrity!",
    gameFormat: "Bidding",
    date: "6th March 2024",
    venue: "B 401 (Full Day)",
    entryFee: "Free",
    registrationLink:
      "https://unstop.com/college-fests/bvest24-bharati-vidyapeeths-college-of-engineering-208728",
  },
  {
    id: 2,
    eventName: "GEEK OUT 2.0",
    eventDescription:
      "Get ready to flex your coding muscles in this exhilarating challenge! Teams will have 30 minutes to solve a series of jumbled code problems. Top scorers advance to the next round where they'll decode input-output pairs into functional code. With points awarded based on difficulty, it's a battle of wits and logic!",
    gameFormat: "Competitoin",
    date: "6th March 2024",
    venue: "A101",
    entryFee: "Free",
    registrationLink:
      "https://unstop.com/college-fests/bvest24-bharati-vidyapeeths-college-of-engineering-208728",
  },
  {
    id: 3,
    eventName: "ROBOSOCCER",
    eventDescription:
      "Calling all soccer fans! Experience the future of the sport with RoboSoccer! Teams design and compete with robots on the field, showcasing creativity and strategy. Score points by outmaneuvering opponents. It's a thrilling blend of robotics and soccer!",
    gameFormat: "Robotics",
    date: "5th March 2024",
    venue: "Basketball Court",
    entryFee: "Free",
    registrationLink:
      "https://unstop.com/college-fests/bvest24-bharati-vidyapeeths-college-of-engineering-208728",
  },
  {
    id: 4,
    eventName: "DESIGN CHALLENGE",
    eventDescription:
      "Unleash your creativity in this UI/UX design competition! Participants choose between solving a real-world problem or revamping an existing website. With a focus on user experience and innovation, designs will be judged on responsiveness, mobile optimization, and presentation skills. May the most innovative designs win!",
    gameFormat: "Design",
    date: "6th March 2024",
    venue: "A107",
    entryFee: "Free",
    registrationLink:
      "https://unstop.com/college-fests/bvest24-bharati-vidyapeeths-college-of-engineering-208728",
  },
  {
    id: 5,
    eventName: "Aavishkaran",
    eventDescription:
      "Present your entrepreneurial ideas to seasoned judges in this competitive event! Submit comprehensive business plans covering sustainability and financial projections. Top teams pitch their ideas, competing for prizes and mentorship to turn their visions into reality.",
    gameFormat: "ENTREPRENEURSHIP",
    date: "6th March 2024",
    venue: "B404",
    entryFee: "Free",
    registrationLink:
      "https://unstop.com/college-fests/bvest24-bharati-vidyapeeths-college-of-engineering-208728",
  },
  {
    id: 6,
    eventName: "Zenith",
    eventDescription:
      'Get ready for the ultimate gaming showdown at "Zenith," a thrilling event presented by IEEE BVCOE and Athena. This gaming extravaganza features three adrenaline-pumping tournaments: FIFA, Tekken-7, and BGMI (Battlegrounds Mobile India).',
    gameFormat: "FIFA, Tekken-7, BGMI",
    date: "6th March 2024",
    venue: "COMPUTER Lab, B 402",
    entryFee:
      "BGMI- Rs 40/- (per head), FIFA(Tournament) - Rs 50/-, Tekken7 - Rs 20/-",
    registrationLink:
      "https://unstop.com/college-fests/bvest24-bharati-vidyapeeths-college-of-engineering-208728",
  },
  {
    id: 7,
    eventName: "Tech-युक्त",
    eventDescription:
      "The event focuses on developing an overall profile to excel in placement and internship drives. Event will be divided into 3 stages which also resonates with the objectives of each collaborating society.",
    gameFormat: "Technical Round, Group Discussion, Mock Interview",
    date: "4th March 2023",
    venue: "B 404",
    entryFee: "Free",
    registrationLink:
      "https://unstop.com/college-fests/bvest24-bharati-vidyapeeths-college-of-engineering-208728",
  },
  {
    id: 8,
    eventName: "Webweave",
    eventDescription:
      'Embark on a journey of creativity and innovation with "WebWeave" an exhilarating UI/UX competition that invites designers to showcase their talent and push the boundaries of user interface and experience.',
    gameFormat: "UI/UX competition",
    date: "5th March 2024",
    venue: "A107",
    entryFee: "Free",
    registrationLink:
      "https://unstop.com/college-fests/bvest24-bharati-vidyapeeths-college-of-engineering-208728",
  },
  {
    id: 9,
    eventName: "TechMonTech Challenge",
    eventDescription:
      "This is a Fun Learning Event with some dynamic and exciting challenges. The Event is a replica of the famous game Capture the Flag where every team needs to solve a particular riddle in order to reach the next Flag.",
    gameFormat: "Problem Solving",
    date: "1st March 2024",
    venue: "A101, A102, A103",
    entryFee: "Free",
    registrationLink:
      "https://unstop.com/college-fests/bvest24-bharati-vidyapeeths-college-of-engineering-208728",
  },
  {
    id: 10,
    eventName: "2 fast 2 curious",
    eventDescription:
      "This will be a quiz round! It will act as the elimination round to the actual Jeopardy competition and will take place just before we conduct Jeopardy.",
    gameFormat: "Quizzing",
    date: "5th March 2024",
    venue: "B 403",
    entryFee: "Free",
    registrationLink:
      "https://unstop.com/college-fests/bvest24-bharati-vidyapeeths-college-of-engineering-208728",
  },
  {
    id: 11,
    eventName: "Agni-Path",
    eventDescription:
      "Welcome to Agni-Path, the ultimate robotics challenge! Picture this: a thrilling competition where autonomous robots showcase their precision and agility as they navigate a challenging track.",
    gameFormat: "Robotics",
    date: "6th March 2024",
    venue: "Basketball Court",
    entryFee: "Free",
    registrationLink:
      "https://unstop.com/college-fests/bvest24-bharati-vidyapeeths-college-of-engineering-208728",
  },
  {
    id: 12,
    eventName: "Oratory Odyssey",
    eventDescription:
      "Oratory Odyssey is an intellectually stimulating event consisting of three rounds designed to test participants' wit and eloquence It aims to provide a platform for participants to exhibit intellectual prowess and articulate communication throughout the competition.",
    gameFormat: "Elocution, Discussion, Debate",
    date: "4th March 2024",
    venue: "B403",
    entryFee: "Free",
    registrationLink:
      "https://unstop.com/college-fests/bvest24-bharati-vidyapeeths-college-of-engineering-208728",
  },
  {
    id: 13,
    eventName: "Microverse",
    eventDescription:
      "Microverse is a hardware battle event comprising two rounds: Trivia and Hands-on Round (Arduino). Participants engage in trivia and practical challenges, showcasing their knowledge and skills in designing and robotics. The event fosters collaboration and practical expertise.",
    gameFormat: "Designing, Robotics",
    date: "5th March 2023",
    venue: "B - 302 , ECE project Lab , EEE or Ece Labs",
    entryFee: "Free",
    registrationLink:
      "https://unstop.com/college-fests/bvest24-bharati-vidyapeeths-college-of-engineering-208728",
  },
  {
    id: 14,
    eventName: "Pitch Perfect",
    eventDescription:
      "Pitch Perfect challenges participants to create comprehensive marketing strategies on the spot. Teams analyze diverse products and craft impromptu marketing strategies. The event emphasizes quick thinking, creativity, and effective communication.",
    gameFormat: "Pitching",
    date: "4th March 2023",
    venue: "B 401",
    entryFee: "Free",
    registrationLink:
      "https://unstop.com/college-fests/bvest24-bharati-vidyapeeths-college-of-engineering-208728",
  },
  {
    id: 15,
    eventName: "GST - Gareeb Shark Tank",
    eventDescription:
      "GST - Gareeb Shark Tank is an entrepreneurship event comprising two rounds: online submission of ideas and live pitching. Participants showcase innovative business ideas and pitch them to a panel of judges. The event encourages creativity, problem-solving, and captivating presentations.",
    gameFormat: "Entrepreneurship , Business , Presentation",
    date: "5th March 2024",
    venue: "B 404",
    entryFee: "Free",
    registrationLink:
      "https://unstop.com/college-fests/bvest24-bharati-vidyapeeths-college-of-engineering-208728",
  },
  {
    id: 16,
    eventName: "Tri - Pysch - el",
    eventDescription:
      "Tri - Pysch - el is a Techno-cultural Ideathon focusing on mental health. It comprises four rounds: online idea submission, ideation, advertisement, and final presentation. The event encourages creativity, technical skills, and mental health awareness.",
    gameFormat:
      "Literary, Poetry, Story Telling, Ideathon, Competition, Technical competiton, mental health, litreature",
    date: "4th March 2023",
    venue: "B404",
    entryFee: "Free",
    registrationLink:
      "https://unstop.com/college-fests/bvest24-bharati-vidyapeeths-college-of-engineering-208728",
  },
  {
    id: 17,
    eventName: "InnoVogue",
    eventDescription:
      'The "InnoVogue" event is not merely a convergence of two industries; it is a celebration of the limitless possibilities that arise when fashion and technology intertwine. In an era where innovation is the driving force behind progress, this event stands as a testament to the transformative power that emerges when creative minds from diverse fields come together.',
    gameFormat: "Fashion, Technology, Ideathon, Competition",
    date: "1st March 2024",
    venue: "B 402",
    entryFee: "Free",
    registrationLink:
      "https://unstop.com/college-fests/bvest24-bharati-vidyapeeths-college-of-engineering-208728",
  },
  {
    id: 18,
    eventName: "PyHunt",
    eventDescription:
      "The PyHunt competition unfolds in two exhilarating rounds, each designed to challenge and showcase the participants' Python prowess and problem-solving skills. Featuring a comprehensive Multiple-Choice Question (MCQ) quiz. This phase not only tests the participants' theoretical knowledge of Python covering various domains like debugging, output guessing, jumble section, etc.",
    gameFormat: "Coding, Python, Problem-solving, Competition",
    date: "1st March 2024",
    venue: "A 107",
    entryFee: "Free",
    registrationLink:
      "https://unstop.com/college-fests/bvest24-bharati-vidyapeeths-college-of-engineering-208728",
  },
  {
    id: 19,
    eventName: "UXpert",
    eventDescription:
      "Welcome to the UI Design round of UXpert, where participants will showcase their prowess in creating innovative and user-friendly interfaces. This challenge invites you to design an interface for an app or website that addresses a specific user need or challenge in the digital space.",
    gameFormat: "Designing, UI/UX, Competition",
    date: "5th March 2024",
    venue: "To be decided",
    entryFee: "Free",
    registrationLink:
      "https://unstop.com/college-fests/bvest24-bharati-vidyapeeths-college-of-engineering-208728",
  },
  {
    id: 20,
    eventName: "Game of Code",
    eventDescription:
      'The "Game of Code" event unfolds in three thrilling rounds, each designed to test participants\' coding prowess and logical thinking. Round One takes competitors on an adventurous quest across the college campus, from the canteen to the labs, where they must solve 20 unique, logic-based questions.',
    gameFormat:
      "Gaming, Coding, Programming, Critical Thinking, Problem Solving, Competition",
    date: "5th March 2024",
    venue: "C102",
    entryFee: "Free",
    registrationLink:
      "https://unstop.com/college-fests/bvest24-bharati-vidyapeeths-college-of-engineering-208728",
  },
];

export default myEventDetailsObjects;
