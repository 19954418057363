import React, { Component } from "react";

export default class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faqData: bvestxFaqData.map((faq) => ({ ...faq, showAnswer: false })),
    };
  }

  toggleAnswer(index) {
    const updatedFAQData = this.state.faqData.map((faq, i) => {
      return {
        ...faq,
        showAnswer: i === index ? !faq.showAnswer : false,
      };
    });

    this.setState({ faqData: updatedFAQData });
  }

  render() {
    return (
      <div style={styles.container}>
        <h1 style={styles.heading}>Frequently Asked Questions</h1>
        <div style={styles.faqContainer}>
          {this.state.faqData.map((faq, index) => (
            <div
              style={styles.card}
              key={index}
              onClick={() => this.toggleAnswer(index)}
              onMouseEnter={() => this.handleCardHover(index, true)}
              onMouseLeave={() => this.handleCardHover(index, false)}>
              <div style={styles.question}>
                <button style={styles.questionButton}>{faq.question}</button>
                <div style={styles.dropdownArrow}>&#9660;</div>
              </div>
              {faq.showAnswer && (
                <div
                  style={styles.answer}
                  dangerouslySetInnerHTML={{ __html: faq.answer }}
                />
              )}{" "}
            </div>
          ))}
        </div>
      </div>
    );
  }

  handleCardHover(index, isHovered) {
    const updatedFAQData = this.state.faqData.map((faq, i) => {
      return {
        ...faq,
        isHovered: i === index ? isHovered : false,
      };
    });

    this.setState({ faqData: updatedFAQData });
  }
}

const styles = {
  container: {
    width: "100%",
    margin: "auto",
    backgroundColor: "#f7f5eb", // Creamy white background
    color: "#2C3E50", // Dark color for text
    padding: "50px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  heading: {
    fontFamily: "Audiowide",
    textAlign: "center",
    fontSize: "4vmax",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  faqContainer: {
    display: "flex",
    flexDirection: "column",
  },
  card: {
    marginBottom: "20px",
    border: "1px solid #ddd",
    borderRadius: "5px",
    overflow: "hidden",
    position: "relative",
    transition: "background-color 0.3s",
    cursor: "pointer",
  },
  question: {
    background: "#f0f0f0",
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  questionButton: {
    border: "none",
    background: "none",
    cursor: "pointer",
    fontSize: "1em",
    fontWeight: "bold",
    textAlign: "left",
  },
  answer: {
    padding: "10px",
  },
  dropdownArrow: {
    fontSize: "1em",
    color: "#555",
  },
};

const bvestxFaqData = [
  {
    question: "What is BVEST?",
    answer:
      "BVEST is the official and Annual Technical Fest of Bharati Vidyapeeth’s College of Engineering, New Delhi.",
  },
  {
    question: "What is BVESTX?",
    answer:
      "BVESTX is the 10th edition of BVEST, the annual Technical Fest of Bharati Vidyapeeth’s College of Engineering, New Delhi. It is organized by the Department of Computer Science Engineering and is themed 'Decoding The Decade.'",
  },
  {
    question: "When is BVESTX 2024?",
    answer:
      "BVESTX 2024 is scheduled to take place on the 05th and 06th of March, 2024.",
  },
  {
    question: "What is the theme of BVESTX?",
    answer:
      "The theme of BVESTX is 'Decoding The Decade.' It serves as an invitation for participants to unravel the mysteries of the technological evolution that has defined the last ten years.",
  },
  {
    question: "What type of events will be there?",
    answer:
      "BVESTX will feature a variety of events including its flagship hackathon, HackBVP 6.0, and 15+ other events comprising gaming, coding, robot wars, ideathons, and more.",
  },
  {
    question: "Who can take part in BVESTX?",
    answer: "All college students are allowed to participate in BVESTX.",
  },
  {
    question: "Where will BVESTX take place?",
    answer:
      "All the events of BVESTX 2024 will take place at Bharati Vidyapeeth’s College of Engineering, New Delhi.",
  },
  {
    question: "How to reach the campus?",
    answer:
      "The campus is located just a few steps away from Paschim Vihar East Metro Station, New Delhi.",
  },
  {
    question: "What do I need to carry?",
    answer:
      "Participants are required to carry a valid identity proof like their college I-Card and Aadhaar card for entry inside the campus.",
  },
  {
    question: "How to register for BVESTX?",
    answer: `You can check out the “Events” section on our website in navbar or go to <a href="https://unstop.com/">https://unstop.com/</a> and type “BVESTX” to explore all the events and complete the registration process.`,
  },
  {
    question: "What is the registration fee for BVESTX?",
    answer:
      "The registration fee is free for the Hackathon and some events, while it is paid for the rest of the events.",
  },
  {
    question: "Will accommodation be provided?",
    answer:
      "No, at this point, the organizers are not providing accommodation for participants in BVESTX.",
  },
];
