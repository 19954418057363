import React, { Component } from "react";

export default class AboutUs extends Component {
  render() {
    return (
      <div style={styles.container}>
        <h1 style={styles.heading}>Number that Matters</h1>
        <div style={styles.cardContainer}>
          {this.renderCard(
            "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/registration.gif",
            "1000+",
            "Registration"
          )}
          {this.renderCard(
            "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/WebsiteImpression.gif",
            "50000+",
            "Website Impression"
          )}
          {this.renderCard(
            "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/Social%20Media%20Impression.gif",
            1500,
            "Social Media Reach"
          )}
          {this.renderCard(
            "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/sponsors.gif",
            10,
            "Sponsors"
          )}
        </div>
      </div>
    );
  }

  renderCard(gifUrl, value, tag) {
    return (
      <div style={styles.card}>
        <img
          src={gifUrl}
          alt="GIF"
          style={styles.gif}
        />
        <h2 style={styles.value}>{value}</h2>
        <p style={styles.tag}>{tag}</p>
      </div>
    );
  }
}

const styles = {
  container: {
    width: "100%", // Full screen width
    padding: "20px", // Padding from all sides
    backgroundColor: "#f7f5eb", // Creamy white background
    backgroundImage:
      "linear-gradient(45deg, #f7f5eb 25%, transparent 25%, transparent 75%, #f7f5eb 75%, #f7f5eb), linear-gradient(-45deg, #f7f5eb 25%, transparent 25%, transparent 75%, #f7f5eb 75%, #f7f5eb)",
    backgroundSize: "20px 20px", // Smoky texture
    color: "#2C3E50", // Dark color for text
  },
  heading: {
    fontFamily: "Audiowide", // Different font for heading
    textAlign: "center",
    fontSize: "3em",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  card: {
    width: "230px",
    textAlign: "center",
    margin: "15px",
    padding: "15px",
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  },
  gif: {
    width: "210px",
    height: "200px",
    borderRadius: "5px",
  },
  value: {
    fontSize: "1.5em",
    fontWeight: "bold",
    marginTop: "10px",
  },
  tag: {
    color: "#555",
    marginTop: "5px",
  },
};
