import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class FacultyCoreTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverIndex: -1,
    };
  }

  handleMouseEnter = (index) => {
    this.setState({ hoverIndex: index });
  };

  handleMouseLeave = () => {
    this.setState({ hoverIndex: -1 });
  };

  render() {
    const teamData = {
      Convener: [
        {
          name: "Dr. Deepika Kumar",
          position: "Convener",
          image:
            "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/Core%20Team/DeepikaKumar.jpg",
          link: "https://www.linkedin.com/in/deepika-kumar-ph-d-26b2a3212/",
        },
      ],
      coConvener: [
        {
          name: "Dr. Jolly Parikh",
          position: "Co-Convener",
          image:
            "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/Core%20Team/Jolly%20Parikh.jpg",
          link: "https://www.linkedin.com/in/jolly-parikh-2051856a/",
        },
        {
          name: "Dr. Gargi Mishra",
          position: "Co-Convener",
          image:
            "https://raw.githubusercontent.com/bvestx/BvestxAsset/main/Core%20Team/GargiMishra.jpg",
          link: "https://www.linkedin.com/in/gargi-mishra-ph-d-2bb09116/",
        },
      ],
    };

    return (
      <div style={styles.container}>
        <h2 style={styles.sectionHeading}>Faculty Core Team</h2>
        <div style={styles.columnContainer}>
          {/* Render conveners */}
          <div style={styles.cardContainer}>
            {teamData.Convener.map((member, index) => (
              <Link
                key={member.name}
                to={member.link}
                style={styles.link}>
                <div
                  style={{
                    ...styles.card,
                    ...(this.state.hoverIndex === index && styles.hoverEffect),
                  }}
                  className="hover-effect"
                  onMouseEnter={() => this.handleMouseEnter(index)}
                  onMouseLeave={this.handleMouseLeave}>
                  <img
                    src={member.image}
                    alt={member.name}
                    style={styles.memberImage}
                  />
                  <div style={styles.memberInfo}>
                    <p>{member.name}</p>
                    <p>{member.position}</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>

          {/* Render co-conveners */}
          <div style={styles.cardContainer}>
            {teamData.coConvener.map((member, index) => (
              <Link
                key={member.name}
                to={member.link}
                style={styles.link}>
                <div
                  style={{
                    ...styles.card,
                    ...(this.state.hoverIndex ===
                      index + teamData.Convener.length && styles.hoverEffect),
                  }}
                  className="hover-effect"
                  onMouseEnter={() =>
                    this.handleMouseEnter(index + teamData.Convener.length)
                  }
                  onMouseLeave={this.handleMouseLeave}>
                  <img
                    src={member.image}
                    alt={member.name}
                    style={styles.memberImage}
                  />
                  <div style={styles.memberInfo}>
                    <p>{member.name}</p>
                    <p>{member.position}</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    marginBottom: "20px",
  },
  sectionHeading: {
    fontFamily: "Audiowide",
    fontSize: "2.5em",
    marginBottom: "10px",
    textAlign: "center",
    fontWeight: "bold",
  },

  cardContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  columnContainer: {
    // display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  card: {
    width: "200px", // Initial width of the card
    flex: "1 1 200px", // Flex properties to allow flexibility
    padding: "10px",
    margin: "10px",
    backgroundColor: "#D6EAF8", // Light blue background
    borderRadius: "10px",
    textAlign: "center",
    cursor: "pointer",
    transition: "background-color 0.3s, border 0.3s",
    position: "relative",
    overflow: "hidden",
  },
  memberImage: {
    width: "100%",
    height: "auto",
    borderRadius: "50%",
    marginBottom: "10px",
    transition: "transform 0.3s",
  },
  memberInfo: {
    fontWeight: "bold",
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  hoverEffect: {
    backgroundColor: "#AED6F1",
    border: "2px dashed #2C3E50",
  },
};
