import React, { Component } from "react";

export default class AboutUs extends Component {
  render() {
    return (
      <div style={styles.container}>
        <h1 style={styles.heading}>
          <span style={styles.aboutBVEST}>About</span>{" "}
          <span style={styles.bvestx}>BVESTX'24</span>
        </h1>
        <div style={styles.content}>
          <p>
            BVEST, the tech fest organized by{" "}
            <span style={styles.aboutBVEST}>
              Bharati Vidyapeeth's College of Engineering
            </span>
            , is gearing up for its highly anticipated tenth edition with an
            intriguing theme
            <span style={styles.aboutBVEST}> "Decoding The Decade."</span>
          </p>
          <p>
            The theme, "Decoding The Decade," serves as an invitation for
            participants to unravel the mysteries of the technological evolution
            that has defined the last ten years. It fosters an environment where
            students can engage in discussions, workshops, and hands-on
            experiences to gain insights into the transformative power of
            technology.
          </p>
          <p>
            In its milestone tenth edition, BVEST endeavors to attract a diverse
            array of young minds, inspiring them to explore the cutting-edge
            developments in various fields such as artificial intelligence,
            blockchain, robotics, and more. By creating an immersive and
            interactive experience, the tech fest aims to ignite curiosity and
            passion for innovation among participants.
          </p>
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    width: "100%", // Full screen width
    padding: "10px", // Padding from all sides
    backgroundColor: "#f7f5eb", // Creamy white background
    backgroundImage:
      "linear-gradient(45deg, #f7f5eb 25%, transparent 25%, transparent 75%, #f7f5eb 75%, #f7f5eb), linear-gradient(-45deg, #f7f5eb 25%, transparent 25%, transparent 75%, #f7f5eb 75%, #f7f5eb)",
    backgroundSize: "20px 20px", // Smoky texture
    color: "#2C3E50", // Dark color for text
  },
  heading: {
    fontFamily: "Times New Roman, serif", // Different font for heading
    textAlign: "center",
  },
  aboutBVEST: {
    color: "#004080", // Different color for "About"
    fontWeight: "bold",
    fontFamily: "Georgia, serif", // Different font for "About"
  },
  bvestx: {
    color: "#8B4513", // Different color for "BVESTX'24"
    fontWeight: "bold",
    fontFamily: "Arial, sans-serif", // Different font for "BVESTX'24"
  },
  content: {
    fontFamily: "Roboto, sans-serif", // Different font for content
    lineHeight: "1.6",
    textAlign: "justify",
    marginTop: "20px",
  },
};
