import React, { Component } from "react";
import StudentCoreTeam from "./StudentCoreTeam";
import StudentCoordinators from "./StudentCoordinators";

export default class OurStudentTeam extends Component {
  render() {
    return (
      <div style={styles.container}>
        <h1 style={styles.heading}>Our Team</h1>
        <StudentCoreTeam />
        <StudentCoordinators />
      </div>
    );
  }
}

const styles = {
  container: {
    width: "100%",
    padding: "20px",
    backgroundColor: "#f7f5eb",
    color: "#2C3E50",
  },
  heading: {
    fontFamily: "Audiowide",
    textAlign: "center",
    fontSize: "4em",
    fontWeight: "bold",
    marginBottom: "20px",
  },
};
